// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-container {
  height: 100vh;
  width: 200px;
  background-color: white;
  padding: 0 10px;
  position: relative;
}

.sidebar-icon {
  min-width: 20px;
}

::ng-deep.widthIcons .sidebar-container {
  max-width: 100%;
}

::ng-deep.widthIcons .sidebar-item {
  max-width: 100%;
  overflow-x: hidden;
}

::ng-deep.widthIcons .logo-image.hidden {
  opacity: 1;
  padding-left: 0;
  overflow-x: hidden;
}

.conn-services-menu .nav-link-icon {
  transition: all 0.2s ease-in-out;
  padding: 0;
  padding-block: 10px;
}

.conn-services-menu .nav-link-icon svg-icon,
.conn-services-menu .nav-link-icon svg-icon svg {
  height: 27px;
}

::ng-deep.widthIcons .conn-services-menu .nav-link-text {
  display: none;
}

::ng-deep.widthIcons .conn-services {
  display: none;
}

::ng-deep .css-0 {
  cursor: pointer;
  pointer-events: none;
}
::ng-deep .css-0 .nc-bell-button {
  width: 20px;
  min-width: 17px;
  display: inline;
  pointer-events: none;
}
::ng-deep .css-0 .nc-bell-button svg {
  pointer-events: none;
  color: #FF8C17;
  fill: #FF8C17;
  height: 27px;
  width: 17px;
}

.sidebar-item-icon {
  width: 20px;
  height: 15px;
}

.sidebar-item-icon-active path,
.sidebar-item-icon-active rect,
.sidebar-item-icon-active circle {
  fill: white;
}

.sidebar-item {
  border-left: 6px solid transparent;
  padding-left: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.sidebar-item path,
.sidebar-item rect,
.sidebar-item circle {
  fill: #FF8C17;
}

.sidebar-item .svg-icon {
  color: #FF8C17;
}

.sidebar-item:hover a {
  color: hsl(30, 100%, 75%);
}

.sidebar-item-active:hover a {
  color: hsl(30, 100%, 75%);
}

.sidebar-item svg-icon,
.sidebar-item svg-icon svg {
  width: 17px;
}

.sidebar-item:hover svg-icon {
  cursor: pointer;
}

.sidebar-item .svg-icon,
.sidebar-item .svg-icon svg {
  width: 17px;
}

.sidebar-item:hover .svg-icon {
  cursor: pointer;
}

.sidebar-item-icon-users {
  height: 13px;
}

.sidebar-item-icon-license {
  height: 15px;
}

.sidebar-item-icon-fleet-mgmt {
  height: 20px;
}

.sidebar-item:hover path,
.sidebar-item:hover rect,
.sidebar-item:hover circle {
  fill: hsl(30, 100%, 75%);
}

.sidebar-item-active:hover path,
.sidebar-item-active:hover rect,
.sidebar-item-active:hover circle {
  fill: hsl(30, 100%, 75%);
}

.sidebar-item-active {
  background: #FF8C17;
  color: white !important;
}
.sidebar-item-active .nav-link {
  color: white !important;
}

.sidebar-item-active path,
.sidebar-item-active rect,
.sidebar-item-active circle {
  fill: white;
}

.sidebar-item-active .svg-icon {
  color: white;
}

.logo-image {
  height: 80px;
  opacity: 1;
  padding-left: 7px;
  position: relative;
  transition: 0.4s all ease;
}

.logo {
  width: 93px;
}

.collapse-sidebar {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5411764706);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px -1px rgba(0, 0, 0, 0.1411764706), 0 1px 3px 0px rgba(0, 0, 0, 0.1215686275);
  border-radius: 4px 0 0 4px;
  opacity: 0.7;
  padding: 0.3rem 0 0rem 0.4rem;
  position: absolute;
  right: 0;
  transition: 0.4s all ease;
}

.nav-link {
  cursor: pointer;
  color: #FF8C17;
  fill: #FF8C17;
  font-size: 0.7rem;
  font-weight: 700;
  padding: 10px 11px;
}

.nav-link.active {
  color: white;
}

.home-active,
.home-grey,
.connections-active,
.connections-grey {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 11px;
  margin-right: 2px;
}

.users-active,
.users-grey,
.license-active,
.license-grey,
.tractor-active,
.fleet-mgmt-grey,
.farm-mgmt-grey,
.field-mgmt-grey,
.tractor-grey {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 13px;
  margin-right: 2px;
}

.fleet-mgmt-grey {
  background-image: url("/assets/icons/fleet.png");
}

.farm-mgmt-grey {
  background-image: url("/assets/icons/farm.png");
}

.field-mgmt-grey {
  background-image: url("/assets/icons/field.png");
}

.tractor-grey {
  background-image: url("/assets/icons/tractor-grey.png");
}

.tractor-active {
  background-image: url("/assets/icons/tractor.png");
}

.home-grey {
  background-image: url("/assets/icons/overview-grey.png");
}

.home-active {
  background-image: url("/assets/icons/overview.png");
}

.connections-grey {
  background-image: url("/assets/icons/connections-grey.png");
}

.connections-active {
  background-image: url("/assets/icons/connections.png");
}

.users-grey {
  background-image: url("/assets/icons/users-grey.png");
}

.users-active {
  background-image: url("/assets/icons/users.png");
}

.license-active {
  background-image: url("/assets/icons/license.png");
}

.license-grey {
  background-image: url("/assets/icons/license-grey.png");
}

.conn-services-menu {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (min-height: 600px) {
  .conn-services-menu {
    bottom: 0;
    position: absolute;
    margin-top: 0;
  }
}
.conn-services-title {
  display: inline;
  font-size: 0.6rem;
  color: #C8C8C8;
  margin-left: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.conn-services-icon {
  vertical-align: middle;
  color: #C8C8C8;
  font-size: 0.8rem !important;
  padding-top: 7px;
  padding-left: 5px;
}

.hidden {
  opacity: 0;
}

.header-user-container, .header-dealer-container {
  padding: 4px 0;
  width: 100%;
  display: grid;
  justify-items: start;
  align-items: center;
  grid-template-columns: 40px 1fr;
  gap: 0.8em;
}

.header-user-container {
  margin-top: 8px;
}

.header-dealer-container {
  border-bottom: 1px solid hsl(30, 100%, 75%);
  margin-bottom: 18px;
  padding-bottom: 12px;
}

.header-user-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin-right: 10px;
}

.header-user-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
}

.header-user-info {
  line-height: 1rem;
}

.header-user-name {
  font-size: 12px;
  line-height: 1.1;
}

.header-user-role {
  font-size: 0.8rem;
  font-weight: 200;
}

.material-icons {
  font-size: 3rem;
}

@media screen and (min-width: 711px) {
  .logo-image {
    display: flex !important;
  }
  .logo-image.hidden {
    opacity: 0;
  }
  ::ng-deep.widthIcons .logo-image.hidden {
    opacity: 1;
  }
}
@media screen and (min-width: 768px) {
  ::ng-deep.widthIcons .logo-image.hidden {
    padding-left: 3px;
  }
  ::ng-deep.widthIcons .header-user-info {
    display: none !important;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
